import { takeLatest, put } from 'redux-saga/effects';
import { ActionTypes } from 'state/types/app';
import { initComplete } from 'state/actions/app';

export function* init() {
	try {
		yield put(initComplete());
	} catch (err) {
		yield put(initComplete());
	}
}

export function* appSaga() {
	yield takeLatest(ActionTypes.INIT, init);
}
