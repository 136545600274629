import React, { ReactElement, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route as Router, Switch, Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import * as serviceWorker from 'service-worker';
import { configureStore } from './store';
import { initApp } from 'state/actions/app';
import { Hype } from 'views/hype/hype.component';

import 'assets/fonts/Inter-Medium.otf';
import 'assets/fonts/Voyage-Bold.otf';
import 'index.scss';

export const history = createBrowserHistory();
export const store = configureStore(history);

declare global {
	interface Window {
		/* eslint-disable-next-line */
		ga: any;
	}
}

history.listen(location => {
	if (window && window.ga) {
		window.ga('set', 'page', location.pathname + location.search);
		window.ga('send', 'pageview');
	}
});

const Root = (): ReactElement => {
	useEffect(() => {
		store.dispatch(initApp());
	}, []);
	return (
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<div>
					<Router>
						<Switch>
							<Route path="/" component={Hype} exact />
							<Redirect to="/" />
						</Switch>
					</Router>
				</div>
			</ConnectedRouter>
		</Provider>
	);
};

render(<Root />, document.getElementById('root') as HTMLElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
