import { combineReducers, Reducer } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { appReducer } from './app';
import { IAppState } from 'state/types/app';

export interface IRootState {
	app: IAppState;
	router: RouterState;
}

export const createRootReducer = (history: History): Reducer =>
	combineReducers<IRootState>({
		app: appReducer,
		router: connectRouter(history),
	});
