import { ActionTypes, IAppState, AppActionTypes } from 'state/types/app';

const initialState: IAppState = {
	initComplete: false,
};

export const appReducer = (state: IAppState = initialState, action: AppActionTypes): IAppState => {
	const nextState = Object.assign({}, state);
	switch (action.type) {
		case ActionTypes.INIT_COMPLETE:
			return { ...nextState, initComplete: true };
		default:
			return nextState;
	}
};
