import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { applyMiddleware, compose, createStore, Store, StoreEnhancer } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddlware from 'redux-saga';
import { createRootReducer } from 'state/reducers';
import { watcherSaga } from 'sagas';

const sagaMiddleware = createSagaMiddlware();
const composeEnhancers = composeWithDevTools || compose;

const getAppliedMiddleware = (routerHistory: History): StoreEnhancer => {
	if (process.env.NODE_ENV === 'development') {
		return applyMiddleware(
			sagaMiddleware,
			routerMiddleware(routerHistory),
			createLogger({
				collapsed: true,
				diff: true,
			}),
		);
	}
	return applyMiddleware(sagaMiddleware, routerMiddleware(routerHistory));
};

export const configureStore = (routerHistory: History, initialState?: {}): Store => {
	const store = createStore(
		createRootReducer(routerHistory),
		initialState,
		composeEnhancers(getAppliedMiddleware(routerHistory)),
	);
	sagaMiddleware.run(watcherSaga);
	return store;
};
