import React, { useEffect } from 'react';
import axios from 'axios';

import BrandXs from 'assets/brand/logos/extra-small.svg';

import './style.scss';

export const Hype = () => {
	useEffect(() => {
		getStatus();
	}, []);

	const getStatus = async () => {
		const { data } = await axios.get('/api/status');
		/* eslint-disable-next-line */
		console.info(data);
	};
	return (
		<div className="app">
			<div className="container">
				<header className="header">Something new is coming your way</header>
				<div className="body-text">An improved StratRoulette is almost here - check back soon!</div>
				<div className="logos">
					<img src={BrandXs} alt="brand-xs" />
				</div>
			</div>
		</div>
	);
};
