import { IActionModel } from 'models';

export enum ActionTypes {
	INIT = 'app/INIT',
	INIT_COMPLETE = 'app/INIT_COMPLETE',
}

export interface IAppState {
	initComplete: boolean;
}

export interface IInit extends IActionModel {
	type: typeof ActionTypes.INIT;
}

export interface IInitComplete extends IActionModel {
	type: typeof ActionTypes.INIT_COMPLETE;
}

export type AppActionTypes = IInit | IInitComplete;
